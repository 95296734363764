<template>
  <div class="add-address-page">
    <div class="addGroup">
      <div class="label">收货人</div>
      <div class="input">
        <input maxlength="16" v-model.trim="receiptname" id="name" type="text">
      </div>
    </div>
    <div class="addGroup">
      <div class="label">联系电话</div>
      <div class="input">
        <input id="tel" v-model.trim="call" type="number" maxlength="11">
      </div>
    </div>
    <div class="addGroup">
      <div class="label">所在地区</div>
      <div class="input formSelect">
        <div id="triggerCity" style="cursor: pointer;"></div>
      </div>
    </div>
    <div class="addGroup target">
      <textarea id="dz" placeholder="请填写详细地址，具体到门牌号"
        style="resize: none; overflow-y: hidden;" v-model.trim="addresss"></textarea>
    </div>
    <div class="save-btn" @click="submitAction">保存</div>
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import cityList from '@/utils/cityAddress';
import { getModifyManageAddress } from '@/api/base';

export default {
  name: 'EditAddress',
  data() {
    return {
      call: '',
      address: '',
      addresss: '',
      receiptname: '',
      cuserid: getAppStatus().cuserId,
    };
  },
  props: {
    from: {
      type: String,
      default: '',
    },
    mid: {
      type: String,
      default: '',
    },
  },
  computed: {
    cuserids() {
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      // return 'df0fdeaa-0f72-44d1-90cd-783d6768b322';
      return getAppStatus().cuserId;
    },
    gerId() {
      const { gerId } = this.$route.query;
      return gerId;
    },
    prizeId() {
      const { prizeId } = this.$route.query;
      return prizeId;
    },
  },
  watch: {
    receiptname(val) {
      val = val.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '');
      this.receiptname = val;
    },
    call(val) {
      val = val.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '');
      this.call = val;
    },
    addresss(val) {
      val = val.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '');
      this.addresss = val;
    },
  },
  mounted() {
    // document.title = this.from === 'edit' ? '编辑收货地址' : '添加收货地址';
    this.cuserId = this.cuserId || sessionStorage.getItem('userId');
    if (!this.cuserid) {
      callAppHandler('jzUserId', null, (res) => {
        sessionStorage.setItem('userId', res);
        this.cuserid = res;
      });
    }
    this.from === 'edit' && this.editAddress();
    console.log(this.$route.query);
    const that = this;
    this.mobileSelectCity = new window.MobileSelect({
      trigger: '#triggerCity',
      title: '',
      wheels: [{
        data: cityList,
      }],
      callback(indexArr, data) {
        let address = [];
        data.forEach((item) => {
          address.push(item.value);
        });
        that.address = address.join(' ');
      },
    });
  },
  methods: {
    async submitAction() {
      let res;
      let {
        receiptname, call, cuserid, address, addresss,
        mid,
      } = this;
      let content = '';
      if (!receiptname) {
        content = '请填写收货人姓名~';
        return this.$toast({
          content,
          duration: 3000,
        });
      }
      if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(call)) {
        content = '请输入正确格式手机号~';
        return this.$toast({
          content,
          duration: 3000,
        });
      }
      console.log(address);
      if (!address) {
        content = '请选择您的地址~';
        return this.$toast({
          content,
          duration: 3000,
        });
      }
      if (!addresss) {
        content = '请填写您的详细地址~';
        return this.$toast({
          content,
          duration: 3000,
        });
      }
      // receiptname = receiptname.replace(/\s*/g, '');
      // call = call.replace(/\s*/g, '');
      console.log(this.from, 'from---');

      try {
        res = await getModifyManageAddress({
          mid: this.from !== 'edit' ? '' : mid,
          cuserid,
          receiptname,
          call,
          address: address + '/' + addresss,
        });
      } catch (e) {
        console.log(e);
        return this.$toast({
          content: e.desc,
          duration: 3000,
        });
      }
      const { query } = this.$route;
      if (res.code === 1) {
        console.log('成功');
        // if (mid) {
        //   history.go(-1);
        // } else {
        //   this.$router.push({
        //     path: 'address',
        //     query,
        //   });
        // }
        // history.go(-1);
        // location.reload();
        this.$emit('child-close');
      }
    },
    editAddress() {
      let addressList = sessionStorage.getItem('addressList');
      if (addressList) {
        addressList = JSON.parse(addressList);
        let { address } = addressList;
        this.receiptname = addressList.receiptname;
        this.call = addressList.call;
        console.log(address.split('/'));
        let arr = address.split('/');
        this.address = arr[0];
        this.addresss = arr[1];
        document.getElementById('triggerCity').innerHTML = arr[0]
         + ' ' + arr[1];
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .add-address-page {
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    position: relative;
    background: #f6f6f6;
    .addGroup {
      background: #ffffff;
      padding: 0.426666rem 0.4rem;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      font-size: 0;
      border-bottom: solid 1px #dddddd;
      .label {
        width: 30%;
        display: inline-block;
        vertical-align: top;
        font-size: 0.426666rem;
        letter-spacing: -0.4px;
        color: #333333;
      }
      .input {
        /*width: 76.82%;*/
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        height: 0.586666rem;
        position: relative;
      }
      .input > input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        border-radius: 0;
        text-align: right;
        color: #333333;
        font-size: 0.373333rem;
        letter-spacing: -0.4px;
      }
      input {
        height: 0.586666rem;
        width: 100%;
        background: transparent;
        border: none;
        border-radius: 0;
        color: #333333;
        font-size: 0.373333rem;
        letter-spacing: -0.4px;
      }
      textarea {
        /*height: 100%;*/
        width: 100%;
        font-family: "微软雅黑";
        background: transparent;
        border: none;
        border-radius: 0;
        color: #333333;
        font-size: 0.373333rem;
        letter-spacing: -0.4px;
        min-height: 0.586666rem;
        height: 0.586666rem;
      }
    }
    .save-btn {
      margin: 1.06rem .37333rem;
      height: 1.17333rem;
      background: linear-gradient(95deg,#ff4a4a,#ff3636);
      border-radius: .58667rem;
      font-size: .45333rem;
      font-weight: 500;
      color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  #triggerCity {
    width: 100%;
    height: 100%;
    color: #333;
    font-size: 0.373333rem;
    padding-right: 0.4rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .formSelect:after {
    content: "";
    background-image: url(./img/arrow.png);
    background-size: cover;
    width: 0.1867rem;
    height: 0.3733rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
